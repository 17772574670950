import { PublicKey } from '@solana/web3.js'

export const isValidSolanaAddress = (address: string): boolean => {
  try {
    // Attempt to create a PublicKey object from the address
    // This will validate the base58 encoding and length
    const publicKey = new PublicKey(address)

    // Verify the address is 32 bytes long when decoded
    return PublicKey.isOnCurve(publicKey)
  } catch (error) {
    // If any error occurs during validation, return false
    return false
  }
}
