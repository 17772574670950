import {
  AddTraderAddressOperationData,
  ChainProtocol,
  Operation,
  OperationProcessStatus,
  OperationStatus,
  OperationType,
  READABLE_OPERATION_TYPE,
  READABLE_PROTOCOL,
  TABLE_HEADER_NAMES,
  TraderAddress,
  TraderHistoryOperation,
} from '@archax/shared-types'
import { Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { getTraderHistory, getTraderHistoryCSVUrl } from '../../../api/traders'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { ExplorerLinks } from '../../../components/ui/ExplorerLinks'
import { OperationStatus as OperationStatusComponent } from '../../../components/ui/OperationStatus'
import { formatDate } from '../../../util/formatters'

interface TraderHistoryProps {
  id: string
}

interface TraderHistoryData extends Operation {
  addressName?: string
  address?: string
  traderAddress?: TraderAddress
}

function formatDetails(data: TraderHistoryData): string {
  let operationData
  switch (data.type) {
    case OperationType.AddTraderAddress:
      operationData = data.data as AddTraderAddressOperationData
      return `Protocol: ${READABLE_PROTOCOL[operationData.protocol as ChainProtocol]}, name: ${
        operationData.addressName
      }, address: ${operationData.address}`
    case OperationType.RemoveTraderAddress:
      return `Name: ${data.traderAddress?.name}, address: ${data.traderAddress?.address}`
    case OperationType.GrantKYC:
      return `Address name: ${data.traderAddress?.name}, token id: ${data.tokenId}`
    case OperationType.RevokeKYC:
      return `Address name: ${data.traderAddress?.name}, token id: ${data.tokenId}`
    case OperationType.Send:
    case OperationType.Wipe:
    case OperationType.Mint:
      const mwsOperationData = data.data as Record<string, unknown>
      let mwsTransactions = 'TXs:'
      if (data.transactions && Array.isArray(data.transactions)) {
        mwsTransactions = `${mwsTransactions} ${(data.transactions || []).map((it) => it.id).join(',')}`
      } else {
        const transaction = data.transactions as unknown as Record<string, unknown>
        mwsTransactions = `${mwsTransactions} ${transaction?.id || transaction?.transactionHash}`
      }
      return `
        Protocol: ${data.traderAddress?.name}, Amount: ${mwsOperationData?.amount}, Token Id: ${data.tokenId}, ${mwsTransactions}
      `
    default:
      return ''
  }
}

function TraderHistory({ id }: TraderHistoryProps) {
  const [showFailed, setShowFailed] = useState(false)

  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'explorer',
        headerName: '',
        cellRenderer: (params: ICellRendererParams<TraderHistoryOperation>) => {
          return <ExplorerLinks operation={params.data!} />
        },
        maxWidth: 48,
        flex: 1,
        sortable: false,
        filter: false,
        cellStyle: { padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' },
      },
      {
        field: 'status',
        cellRenderer: (params: ICellRendererParams<TraderHistoryOperation>) => {
          return <OperationStatusComponent operation={params.data!} />
        },
        headerName: TABLE_HEADER_NAMES.common.operation_status,
        maxWidth: 110,
        flex: 1,
        sortable: false,
        filter: false,
        floatingFilter: false,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 },
      },
      {
        field: 'approvedAt',
        headerName: TABLE_HEADER_NAMES.common.date_time,
        valueGetter: (params: ValueGetterParams<TraderHistoryOperation>) => {
          const date =
            params.data?.status === OperationStatus.Approved ? params.data!.approvedAt : params.data!.updatedAt
          return formatDate(date?.toString())
        },
        flex: 1,
        maxWidth: 160,
        sortable: false,
      },
      {
        field: 'type',
        headerName: TABLE_HEADER_NAMES.common.operation_type,
        valueGetter: (params: ValueGetterParams<TraderHistoryOperation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type as OperationType]
        },
        flex: 1,
        sortable: false,
      },
      {
        field: 'details',
        headerName: TABLE_HEADER_NAMES.common.details,
        valueGetter: (params: ValueGetterParams<TraderHistoryOperation>) => {
          return formatDetails(params.data!)
        },
        flex: 3,
        sortable: false,
      },
    ],
    [],
  )

  const mutateFilters = useCallback(
    (filters: any) => {
      if (!showFailed) {
        filters.operation_status = [OperationProcessStatus.Success]
      }

      return filters
    },
    [showFailed],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      defaultColDef: { filter: false },
      onGridReady({ api }) {
        api.sizeColumnsToFit()
      },
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          History
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Switch
            checked={showFailed}
            onChange={(event) => setShowFailed(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography sx={{ marginRight: 6 }} align="left" variant="body1">
            Show failed actions
          </Typography>
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => getTraderHistory({ ...gridParams, id })}
          csvExportUrlGetter={() => getTraderHistoryCSVUrl(id)}
          mutateFilters={mutateFilters}
        />
      </Box>
    </div>
  )
}

export default TraderHistory
