import {
  APIErrorResponse,
  AddTraderAddressOperationData,
  ChainProtocol,
  CreateTokenOperationData,
  DistributionOperationData,
  Operation,
  OperationRequiredCheck,
  OperationStatus,
  OperationType,
  READABLE_OPERATION_TYPE,
  READABLE_STANDARD,
  TokenStandardType,
} from '@archax/shared-types'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IServerSideGetRowsParams,
  ITooltipParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { AxiosError } from 'axios'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { approveOperation, getOperationsToApprove, rejectOperation } from '../../api/operations'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { formatDate } from '../../util/formatters'
import { getTokenIdOnChain } from '../../util/get-token-id-on-chain'
import onApiError from '../../util/on-api-error'
import ChangeOwnerDetails from './components/ChangeOwnerDetails'
import { readableTokenProtocol } from '../../util/token-utils'
import OperationDetailsModalContent from './components/OperationDetailsModalContent'
import { DistributionForm } from './components/DistributionForm'
import { ShowDocumentsButton, ShowDocumentsDialog } from './components/ShowDocuments'

interface ApprovalRow {
  id: string
  tokenName: string
  action: string
  requestor: string
  protocol: string
  standard: string
  status: OperationStatus
  version: number
  canBeApproved: boolean
  requiredChecks: OperationRequiredCheck[]
}

interface SelectedRow {
  row?: ApprovalRow | Operation
  api?: GridApi
}

const tryGetProtocol = (operation: Operation): string | undefined => {
  if (!operation || !operation.data) {
    return
  }
  const { data: operationData } = operation
  let protocol = undefined
  if ('protocol' in operationData) {
    protocol = readableTokenProtocol(operationData.protocol as ChainProtocol)
  }
  if ('token' in operation && operation.token) {
    protocol = readableTokenProtocol(
      operation.token?.standard.chain.protocol as ChainProtocol,
      operation.token?.privacyEnabled,
    )
  }

  return protocol
}

function renderDetailsDialog(operation: Operation, onClose: () => void): ReactElement | undefined {
  const { type, token, traderAddress, data } = operation
  const detailsData = (data || {}) as any
  let children = <></>
  switch (type) {
    case OperationType.Distribution: {
      const detailsData = data as DistributionOperationData & { distributionId: string }
      children = (
        <DistributionForm
          token={token!}
          defaults={detailsData}
          onClose={onClose}
          distributionId={detailsData.distributionId}
        />
      )
      break
    }
    case OperationType.ChangeOwner:
      children = <ChangeOwnerDetails operationData={detailsData} token={token!} />
      break
    default:
      children = (
        <OperationDetailsModalContent operationData={detailsData} token={token!} traderAddress={traderAddress} />
      )
      break
  }

  return (
    <Dialog title={''} onClose={onClose} open={true}>
      {children}
    </Dialog>
  )
}

const RequiredChecksTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} sx={{ opacity: '1 !important' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    maxWidth: '36rem',
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    li: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))

function AdminApproval() {
  const [showDocumentsDialog, setShowDocumentsDialog] = useState(false)
  const [showDetailsDialog, setShowDetailsDialog] = useState(false)
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<SelectedRow>({})

  const { t } = useTranslation()

  const handleApprove = async ({ row, api }: SelectedRow) => {
    try {
      await approveOperation(row!.id, row!.version)
      toast.success('Request approved. The operation is now being processed.')
      api?.refreshServerSide()
      setShowApproveDialog(false)
    } catch (error) {
      onApiError(error as AxiosError<APIErrorResponse>)
    }
  }

  const handleReject = async ({ row, api }: SelectedRow) => {
    try {
      await rejectOperation(row!.id, row!.version)
      toast.success('Request rejected')
      api?.refreshServerSide()
      setShowRejectDialog(false)
    } catch (error) {
      onApiError(error as AxiosError<APIErrorResponse>)
    }
  }

  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          const data =
            operation.type === OperationType.CreateToken
              ? (operation.data as CreateTokenOperationData)
              : operation.token
          return data ? `${data?.name} (${data?.symbol})` : ''
        },
        tooltipValueGetter: (params: ITooltipParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken ? '' : getTokenIdOnChain(operation.token!)
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type]
        },
      },
      {
        field: 'requestor.name',
        headerName: 'Creator',
        minWidth: 150,
        sortable: false,
      },
      {
        field: '',
        headerName: 'Wallet address',
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          const address = (operation.data as AddTraderAddressOperationData).address
          return address || ''
        },
      },
      {
        field: 'protocol',
        headerName: 'Protocol',
        minWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          switch (operation.type) {
            case OperationType.CreateToken:
              return readableTokenProtocol(
                (operation.data as CreateTokenOperationData).protocol as ChainProtocol,
                (operation.data as CreateTokenOperationData).privacyEnabled,
              )
            case OperationType.AddTraderAddress:
              return readableTokenProtocol(
                operation.token?.standard.chain.protocol as ChainProtocol,
                operation.token?.privacyEnabled,
              )
            default:
              return tryGetProtocol(operation)
          }
        },
      },
      {
        field: 'standard',
        headerName: 'Standard',
        minWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken
            ? READABLE_STANDARD[(operation.data as CreateTokenOperationData).standard as TokenStandardType]
            : READABLE_STANDARD[operation.token?.standard.name as TokenStandardType]
        },
      },
      {
        field: 'trader.name',
        headerName: t('headers.common.holderName'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.trader?.name || ''
        },
      },
      {
        field: 'operation.traderAddress.name',
        headerName: t('headers.common.holderAddressName'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          if (operation.type === OperationType.AddTraderAddress) {
            return (operation.data as AddTraderAddressOperationData).addressName
          }
          return operation.traderAddress?.name || ''
        },
      },
      {
        field: 'operation.traderAddress.address',
        headerName: t('headers.common.holderAddress'),
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          if (operation.type === OperationType.AddTraderAddress) {
            return (operation.data as AddTraderAddressOperationData).address
          }
          return operation.traderAddress?.address || ''
        },
      },
      {
        field: 'dataDetails.token.name',
        headerName: t('headers.common.underlyingTokenName'),
        sortable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Requested time',
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return formatDate(operation.createdAt.toString())
        },
      },
      {
        field: 'documents',
        minWidth: 201,
        sortable: false,
        headerName: 'Documents',
        cellRenderer: (params: ICellRendererParams<Operation>) => {
          if ((params.data?.data as CreateTokenOperationData).tempDocuments) {
            return (
              <ShowDocumentsButton
                onClick={() => {
                  setSelectedRow({ row: params.data! })
                  setShowDocumentsDialog(true)
                }}
              />
            )
          }
        },
      },
      {
        field: 'details',
        pinned: 'right',
        minWidth: 201,
        sortable: false,
        headerName: 'Details',
        cellRenderer: (params: ICellRendererParams<Operation>) => {
          const { data } = params.data || {}
          const detailsData = (data || {}) as any
          if (!Object.keys(detailsData).length) {
            return
          }

          return (
            <Button
              onClick={() => {
                setSelectedRow({ row: params.data! })
                setShowDetailsDialog(true)
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
              variant="contained"
              size="small"
            >
              View Details
            </Button>
          )
        },
      },
      {
        field: 'actions',
        minWidth: 200,
        sortable: false,
        pinned: 'right',
        cellRenderer: (params: ICellRendererParams<ApprovalRow>) => {
          if (params.data!.status === OperationStatus.InProgress) {
            return (
              <Typography variant="caption">
                <CircularProgress sx={{ verticalAlign: 'middle', marginRight: 1 }} size={14} />
                Processing...
              </Typography>
            )
          }

          if (params.data!.status === OperationStatus.Queued) {
            return <Typography variant="caption">Queued</Typography>
          }

          return (
            <Stack direction={'row'}>
              {params.data!.canBeApproved && (
                <IconButton
                  data-testid="approve-button"
                  aria-label="Approve"
                  onClick={() => {
                    setSelectedRow({ row: params.data!, api: params.api })
                    setShowApproveDialog(true)
                  }}
                  sx={{ '&:hover': { backgroundColor: 'success.light' } }}
                >
                  <CheckCircleIcon sx={{ color: 'success.main' }} />
                </IconButton>
              )}
              {!params.data!.canBeApproved && (
                <RequiredChecksTooltip
                  title={
                    <>
                      <List>
                        {params.data!.requiredChecks.map((check, index) => (
                          <>
                            <ListItem key={index}>
                              <ListItemAvatar>
                                {check.passed ? (
                                  <CheckIcon sx={{ color: 'success.main' }} />
                                ) : (
                                  <CloseIcon sx={{ color: 'error.light' }} />
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={check.name}
                                secondary={
                                  <>
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {check.description}
                                    </Typography>
                                    {!check.passed && <Typography variant="body2">{check.actionRequired}</Typography>}
                                  </>
                                }
                              />
                            </ListItem>
                          </>
                        ))}
                      </List>
                    </>
                  }
                >
                  <IconButton
                    data-testid="warning-button"
                    aria-label="Warning"
                    sx={{ '&:hover': { backgroundColor: 'warning.light' } }}
                  >
                    <WarningIcon sx={{ color: 'warning.main' }} />
                  </IconButton>
                </RequiredChecksTooltip>
              )}
              <IconButton
                data-testid="reject-button"
                aria-label="Reject"
                onClick={() => {
                  setSelectedRow({ row: params.data!, api: params.api })
                  setShowRejectDialog(true)
                }}
                sx={{ '&:hover': { backgroundColor: 'error.light' } }}
              >
                <CancelIcon sx={{ color: 'error.main' }} />
              </IconButton>
            </Stack>
          )
        },
      },
    ],
    [t],
  )

  const gridOptions: GridOptions<Operation> = useMemo(
    () => ({
      columnDefs,
      defaultColDef: { filter: false, resizable: true },
      tooltipShowDelay: 500,
      tooltipHideDelay: 5000,
      onFirstDataRendered: (params: FirstDataRenderedEvent) => {
        params.columnApi.autoSizeAllColumns()
      },
    }),
    [columnDefs],
  )

  const checkIfNeedRefreshData = useCallback(({ data }: any = [], params: IServerSideGetRowsParams) => {
    const refreshData = data.some((operation: Operation) =>
      [OperationStatus.InProgress, OperationStatus.Queued].includes(operation.status),
    )
    if (refreshData) {
      setTimeout(() => params.api?.refreshServerSide(), 2000)
    }
  }, [])

  const serverSideGridMemoized = useMemo(() => {
    return (
      <ServerSideGrid
        gridOptions={gridOptions}
        queryFn={getOperationsToApprove}
        onGetRowsSuccess={checkIfNeedRefreshData}
      />
    )
  }, [checkIfNeedRefreshData, gridOptions])

  return (
    <Container data-testid="admin-approval" maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography data-testid="admin-approval__title" align="left" variant="h3">
          Admin approval
        </Typography>
        <Typography align="left">Manage requests of other users</Typography>
        <Box>{serverSideGridMemoized}</Box>
      </Card>
      <Dialog
        title="Are you sure that you want to approve the request?"
        onConfirm={() => handleApprove(selectedRow!)}
        confirmLabel="Approve"
        onClose={() => setShowApproveDialog(false)}
        open={showApproveDialog}
        showCancel
      >
        <Typography variant="body2"></Typography>
      </Dialog>
      <Dialog
        title="Are you sure that you want to reject the request?"
        onConfirm={() => handleReject(selectedRow!)}
        confirmLabel="Reject"
        onClose={() => setShowRejectDialog(false)}
        open={showRejectDialog}
        showCancel
      >
        <Typography variant="body2"></Typography>
      </Dialog>
      {showDetailsDialog &&
        selectedRow?.row &&
        renderDetailsDialog(selectedRow.row as Operation, () => {
          setShowDetailsDialog(false)
          setSelectedRow({})
        })}

      {showDocumentsDialog && selectedRow?.row && (
        <ShowDocumentsDialog
          operationId={(selectedRow.row as Operation).id}
          operationType={(selectedRow.row as Operation).type}
          onClose={() => setShowDocumentsDialog(false)}
        />
      )}
    </Container>
  )
}

export default AdminApproval
