import { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  ChainProtocol,
  Currency,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  READABLE_TOKEN_TYPE,
  TokenFormData,
  TokenStandardType,
  TokenType,
} from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { FormMode } from '../../../../api/types'
import Select, { SelectOption } from '../../../../components/ui/Select/Select'
import { Switch } from '../../../../components/ui/Switch'
import TextField from '../../../../components/ui/TextField/TextField'
import {
  DISABLED_CHAINS,
  PROTOCOLS_WITH_PRIVACY_ENABLED,
  PROTOCOLS_WITH_SUPPORT_TO_BLACKLIST,
  PROTOCOLS_WITH_WHITELIST_REQUIRED,
  STANDARDS_WITH_DECIMALS,
  STANDARDS_WITH_PREMINT,
  TOKENS_DOCUMENTS_SIZE_LIMIT,
  TOKENS_DOCUMENTS_SIZE_LIMIT_FORMATTED,
} from '../../../../constants'
import { isEVMProtocol } from '../../../../util/token-options'
import { unparseUnits } from '../../../../util/units'
import { tokenAmountValidator } from '../../../../util/yup-validators'
import { DragDropFiles } from '../DragDropFiles'

const validationSchema = Yup.object()
  .shape({
    tokenType: Yup.string().oneOf(Object.values(TokenType)).required(),
    name: Yup.string().max(20).required(),
    protocol: Yup.string().required(),
    symbol: Yup.string().when('protocol', ([protocol], schema) => {
      if (protocol === ChainProtocol.XRP_Ledger) {
        return schema.length(3).required()
      }
      return schema.max(6).required()
    }),
    standard: Yup.string().required(),
    numberToCreate: Yup.string().when('decimals', ([decimals]) => {
      if (decimals !== undefined && decimals !== null && !isNaN(decimals) && decimals >= 0 && decimals <= 19) {
        return tokenAmountValidator({ decimals: decimals as any as number })
      }
      return Yup.string().notRequired()
    }),
    notes: Yup.string().max(100),
    currency: Yup.string().when('tokenType', (tokenType, schema) => {
      if (tokenType[0] === TokenType.Regular) {
        return schema.oneOf(Object.values(Currency)).required()
      }
      return schema
    }),
    document: Yup.mixed().optional(),
    kycEnabled: Yup.boolean().optional(),
    blacklistEnabled: Yup.boolean().optional(),
    decimals: Yup.number()
      .typeError('Please enter a valid number between 0 and 19.')
      .min(0, 'Please enter a valid number between 0 and 19.')
      .max(19, 'Please enter a valid number between 0 and 19.')
      .default(0)
      .required('Please enter a valid number between 0 and 19.'),
  })
  .required()

const initialValues = {
  name: '',
  symbol: '',
  protocol: '',
  standard: '',
  numberToCreate: '0',
  notes: '',
  currency: '',
  tokenType: '',
  document: undefined,
  privacyEnabled: false,
  kycEnabled: true,
  blacklistEnabled: false,
  decimals: 0,
} as Record<string, unknown>

const algorandStandardOptions = [{ label: 'ASA', value: TokenStandardType.ASA }]

const evmPoolStandardOptions = [
  { label: READABLE_STANDARD[TokenStandardType.ERC20PoolToken], value: TokenStandardType.ERC20PoolToken },
]

const evmStandardOptions: SelectOption[] = [
  { label: 'ERC20', value: TokenStandardType.ERC20 },
  { label: 'ERC721', value: TokenStandardType.ERC721 },
]

const hederaTokenStandardOptions = [
  { label: 'Fungible', value: TokenStandardType.FungibleCommon },
  { label: 'Non-fungible', value: TokenStandardType.NonFungibleUnique },
]
const xrplTokenStandardOptions = [{ label: 'Fungible', value: TokenStandardType.FungibleCommon }]
const solanaStandardOptions = [
  { label: 'Fungible', value: TokenStandardType.FungibleCommon },
  { label: 'Non-fungible', value: TokenStandardType.NonFungibleUnique },
]

const hederaPoolStandardOptions = [
  { label: READABLE_STANDARD[TokenStandardType.FungibleCommonPool], value: TokenStandardType.FungibleCommonPool },
]
interface TokenFormProps {
  mode: FormMode
  onSubmit: (data: TokenFormData) => Promise<void>
  defaultValues?: TokenFormData
}

const poolProtocolOptions = [
  { label: READABLE_PROTOCOL[ChainProtocol.Arbitrum], value: ChainProtocol.Arbitrum },
  { label: READABLE_PROTOCOL[ChainProtocol.Ethereum], value: ChainProtocol.Ethereum },
  { label: READABLE_PROTOCOL[ChainProtocol.Etherlink], value: ChainProtocol.Etherlink },
  { label: READABLE_PROTOCOL[ChainProtocol.Hedera], value: ChainProtocol.Hedera },
  { label: READABLE_PROTOCOL[ChainProtocol.Polygon], value: ChainProtocol.Polygon },
  { label: READABLE_PROTOCOL[ChainProtocol.XDC], value: ChainProtocol.XDC },
].filter((it) => !DISABLED_CHAINS.includes(it.value))

const tokenProtocolOptions = [
  { label: READABLE_PROTOCOL[ChainProtocol.Algorand], value: ChainProtocol.Algorand },
  { label: READABLE_PROTOCOL[ChainProtocol.Arbitrum], value: ChainProtocol.Arbitrum },
  { label: READABLE_PROTOCOL[ChainProtocol.Ethereum], value: ChainProtocol.Ethereum },
  { label: READABLE_PROTOCOL[ChainProtocol.Etherlink], value: ChainProtocol.Etherlink },
  { label: READABLE_PROTOCOL[ChainProtocol.Hedera], value: ChainProtocol.Hedera },
  { label: READABLE_PROTOCOL[ChainProtocol.Polygon], value: ChainProtocol.Polygon },
  { label: READABLE_PROTOCOL[ChainProtocol.Solana], value: ChainProtocol.Solana },
  { label: READABLE_PROTOCOL[ChainProtocol.XDC], value: ChainProtocol.XDC },
  { label: READABLE_PROTOCOL[ChainProtocol.XRP_Ledger], value: ChainProtocol.XRP_Ledger },
].filter((it) => !DISABLED_CHAINS.includes(it.value))

const protocolOptions = {
  [TokenType.Pool]: poolProtocolOptions,
  [TokenType.Regular]: tokenProtocolOptions,
}

function TokenForm({ onSubmit, defaultValues, mode }: TokenFormProps) {
  const navigate = useNavigate()
  const [standardOptions, setStandardOptions] = useState<SelectOption[]>([])
  const [showPrivacyEnabledField, setShowPrivacyEnabledField] = useState(false)
  const [showPremintField, setShowPremintField] = useState(false)
  const [showDecimalsField, setShowDecimalsField] = useState(false)
  const [protocolSelectOptions, setProtocolSelectOptions] = useState<SelectOption[]>([])
  const [isTokenRegular, setIsTokenRegular] = useState(false)
  const [IsUploadAllowed, setIsUploadAllowed] = useState(false)

  const [isWhitelistRequired, setIsWhitelistRequired] = useState(false)
  const [isBlacklistAllowed, setIsBlacklistAllowed] = useState(true)

  const submitButtonLabel = mode === FormMode.Create ? 'Create' : 'Update'

  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid, isSubmitting },
    reset,
  } = useForm<TokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || initialValues,
    resolver: yupResolver(validationSchema),
  })

  const protocolStandardOptionsMap = useMemo(
    () => ({
      [ChainProtocol.Algorand]: algorandStandardOptions,
      [ChainProtocol.Arbitrum]: isTokenRegular ? evmStandardOptions : evmPoolStandardOptions,
      [ChainProtocol.Ethereum]: isTokenRegular ? evmStandardOptions : evmPoolStandardOptions,
      [ChainProtocol.Etherlink]: isTokenRegular ? evmStandardOptions : evmPoolStandardOptions,
      [ChainProtocol.Hedera]: isTokenRegular ? hederaTokenStandardOptions : hederaPoolStandardOptions,
      [ChainProtocol.Polygon]: isTokenRegular ? evmStandardOptions : evmPoolStandardOptions,
      [ChainProtocol.Solana]: solanaStandardOptions,
      [ChainProtocol.XDC]: isTokenRegular ? evmStandardOptions : evmPoolStandardOptions,
      [ChainProtocol.XRP_Ledger]: xrplTokenStandardOptions,
    }),
    [isTokenRegular],
  )

  useEffect(() => {
    if (mode === FormMode.Edit) {
      setProtocolSelectOptions(protocolOptions[defaultValues?.tokenType as TokenType.Pool | TokenType.Regular])
      setIsTokenRegular(defaultValues?.tokenType === TokenType.Regular)
      const newOptions = protocolStandardOptionsMap[defaultValues?.protocol as ChainProtocol]
      setShowPrivacyEnabledField(PROTOCOLS_WITH_PRIVACY_ENABLED.includes(defaultValues?.protocol as ChainProtocol))
      setStandardOptions(newOptions)
      setIsUploadAllowed(isEVMProtocol(getValues('protocol') as ChainProtocol))
      setValue('document', defaultValues?.tempDocuments)
      setValue('standard', defaultValues!.standard)
      setValue('numberToCreate', unparseUnits(defaultValues!.numberToCreate!, defaultValues!.decimals!))
    }
  }, [defaultValues, mode, protocolStandardOptionsMap, setValue, getValues])

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'protocol') {
        const newOptions = protocolStandardOptionsMap[value.protocol as ChainProtocol]
        setStandardOptions(newOptions)
        setShowPrivacyEnabledField(PROTOCOLS_WITH_PRIVACY_ENABLED.includes(value.protocol as ChainProtocol))

        const blacklistAllowed = PROTOCOLS_WITH_SUPPORT_TO_BLACKLIST.includes(value.protocol as ChainProtocol)
        setIsBlacklistAllowed(blacklistAllowed)
        if (!blacklistAllowed) {
          setValue('blacklistEnabled', false)
        }

        const whitelistRequired = PROTOCOLS_WITH_WHITELIST_REQUIRED.includes(value.protocol as ChainProtocol)
        setIsWhitelistRequired(whitelistRequired)
        if (whitelistRequired) {
          setValue('kycEnabled', true)
        }
      }
      if (name === 'standard') {
        const allowPremint = STANDARDS_WITH_PREMINT.includes(value.standard as TokenStandardType)
        setShowPremintField(allowPremint)
        const allowDecimals = STANDARDS_WITH_DECIMALS.includes(value.standard as TokenStandardType)
        setShowDecimalsField(allowDecimals)
      }
      if (name === 'tokenType') {
        setProtocolSelectOptions(protocolOptions[value.tokenType as TokenType.Pool | TokenType.Regular])
        setIsTokenRegular(value.tokenType === TokenType.Regular)
        reset({ ...initialValues, tokenType: value.tokenType })
        setStandardOptions([])
      }
      if (name === 'document') {
        if (value.document) {
          const documentsSize = Object.values(value.document).reduce((acc: number, file: any) => acc + file.size, 0)
          if (documentsSize > TOKENS_DOCUMENTS_SIZE_LIMIT) {
            toast.error(`Please choose a file that is ${TOKENS_DOCUMENTS_SIZE_LIMIT_FORMATTED} or smaller`)
            reset({ ...initialValues, document: undefined })
          }
        }
      }
      if (name === 'tokenType' || name === 'protocol') {
        setIsUploadAllowed(isEVMProtocol(getValues('protocol') as ChainProtocol))
      }
    })
  }, [watch, reset, protocolStandardOptionsMap, getValues])

  return (
    <form
      style={{
        marginTop: '32px',
      }}
      onSubmit={handleSubmit(async (data) => {
        await onSubmit(data)
        reset({ ...initialValues })
        setShowPrivacyEnabledField(false)
        setShowPremintField(false)
        setShowDecimalsField(false)
        setIsTokenRegular(false)
        setIsUploadAllowed(false)
        setIsBlacklistAllowed(true)
      })}
    >
      <Select
        disabled={mode === FormMode.Edit}
        label="Token type"
        name="tokenType"
        control={control}
        options={[
          { label: READABLE_TOKEN_TYPE[TokenType.Regular], value: TokenType.Regular },
          { label: READABLE_TOKEN_TYPE[TokenType.Pool], value: TokenType.Pool },
        ]}
      />
      <Select name="protocol" control={control} label="Protocol" options={protocolSelectOptions} />
      <Select name="standard" control={control} label="Standard" options={standardOptions} />

      {showPrivacyEnabledField && (
        <Switch
          name="privacyEnabled"
          control={control}
          label="Enable privacy"
          tooltip="Add privacy with Silent Data [Rollup]"
        />
      )}
      <Switch name="kycEnabled" control={control} label="Whitelist" disabled={isWhitelistRequired} />
      <Switch name="blacklistEnabled" control={control} label="Blacklist" disabled={!isBlacklistAllowed} />

      <TextField name="name" control={control} label="Name" />
      <TextField name="symbol" control={control} label="Symbol" />
      {showDecimalsField && <TextField name="decimals" type="number" control={control} label="Decimals" />}
      {showPremintField && (
        <TextField name="numberToCreate" type="number" control={control} label="Number to create (optional)" />
      )}
      {isTokenRegular && (
        <Select
          name="currency"
          control={control}
          label="Currency"
          options={Object.values(Currency).map((currencyCode) => ({
            label: currencyCode,
            value: currencyCode,
          }))}
        />
      )}

      <TextField name="notes" control={control} label="Notes (optional)" />

      {IsUploadAllowed && (
        <DragDropFiles
          getValues={getValues}
          setvalue={setValue}
          mode={mode}
          oldDocuments={defaultValues?.tempDocuments}
        />
      )}

      <ButtonGroup fullWidth>
        {mode === FormMode.Edit && (
          <Button
            size="large"
            fullWidth
            onClick={() => {
              navigate('/tokens/requests/edit')
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          {submitButtonLabel}
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default TokenForm
