import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { ChainProtocol, Token } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import WalletAddressInput, { walletAddressValidator } from '../../../../components/ui/WalletAddressInput'
import { StyledCircularProgress, StyledForm } from './styled-components'
import { FormMode } from '../../../../api/types'

export type DefaultValuesFormData = {
  newOwnerAddress: string
}

type FormData = {
  newOwnerAddress?: string
}

const currentOwnerAddressMap = {
  [ChainProtocol.Solana]: (token: Token) => {
    return token.deploymentResult?.authorityAddress
  },
} as Record<ChainProtocol, (token: Token) => string>

type Props = {
  mode: FormMode
  defaultValues?: DefaultValuesFormData
  token: Token
  onCancel?: () => void
  onSubmit: (operationData: Record<string, unknown>) => void
}
export default function DefaultForm({ mode, defaultValues, token, onCancel, onSubmit }: Props) {
  const currentOwnerAdddress =
    currentOwnerAddressMap[token.standard.chain.protocol]?.(token) || token.deploymentResult?.owner?.address || ''

  const {
    privacyEnabled,
    standard: {
      chain: { protocol },
    },
  } = token
  const initialValues = {
    newOwnerAddress: defaultValues?.newOwnerAddress || '',
  }

  const validationSchema = Yup.object()
    .shape({
      newOwnerAddress: walletAddressValidator(protocol)
        .test({
          message: 'The address must different from the current owner',
          test: (value) => !!currentOwnerAdddress && !!value && currentOwnerAdddress !== value,
        })
        .required('New Owner address is required'),
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const submitForm = async (formData: FormData) => {
    if (!isSubmitting) {
      return onSubmit({
        newOwnerAddress: formData.newOwnerAddress,
      })
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit(submitForm)}>
      <WalletAddressInput
        control={control}
        label="New Owner address"
        name="newOwnerAddress"
        protocol={protocol}
        privacyEnabled={privacyEnabled}
      />
      <Box m={1} />
      <Typography align="center" variant="body2">
        Please confirm the requested operation for address
      </Typography>
      <Box m={4} />
      <ButtonGroup fullWidth>
        {onCancel && (
          <Button size="large" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={!isValid}>
          {isSubmitting && <StyledCircularProgress size={'1.2rem'} />}
          {isSubmitting ? 'Submitting' : 'Submit'}
        </Button>
      </ButtonGroup>
    </StyledForm>
  )
}
