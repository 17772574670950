export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-UK', { hour: '2-digit', minute: '2-digit' })
}

export function formatBigNumber(value: string, decimals: number) {
  const stringValue = `${value}`.padStart(decimals, '0')
  const before = stringValue.substring(0, stringValue.length - decimals)
  let after = stringValue.substring(stringValue.length - decimals, stringValue.length)
  if (after.split('').some((v) => v !== '0')) {
    return `${new Intl.NumberFormat('en-US', { useGrouping: true }).format(Number(before || 0))}.${after}`
  }
  return `${new Intl.NumberFormat('en-US', { useGrouping: true }).format(Number(before || 0))}`
}
