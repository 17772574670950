import { isValidAddress } from '@archax/address-validator'
import {
  AddressCreationFormData,
  AddTraderAddressOperationData,
  ChainProtocol,
  OperationType,
  READABLE_PROTOCOL,
} from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import Select from '../../../../components/ui/Select/Select'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { DISABLED_CHAINS } from '../../../../constants'

interface AddAddressProps {
  traderId: string
  onSuccess: () => void
}

const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required().max(100),
    address: Yup.string()
      .test('is-valid-address', 'Invalid address', (value: string | undefined, ctx: Yup.TestContext): boolean => {
        const { protocol } = ctx.parent
        if (!value || !protocol) {
          return false
        }
        // TODO: move this to address-validator
        // This is a temporary solution to validate Hedera addresses
        // ref: https://github.com/hashgraph/hedera-sdk-js/blob/develop/src/account/AccountId.js#L63
        // JIRA task: https://appliedblockchain.atlassian.net/browse/ARX-254
        if (protocol === ChainProtocol.Hedera) {
          const accountIdRegex = /^(\d+)(?:\.(\d+)\.([a-fA-F0-9]+))?(?:-([a-z]{5}))?$/
          return accountIdRegex.test(value)
        }
        return isValidAddress(value, protocol)
      })
      .required(),
    protocol: Yup.string().required(),
  })
  .required()

const initialValues = { name: '', address: '', protocol: '' }
const tokenProtocolOptions = [
  { label: READABLE_PROTOCOL[ChainProtocol.Algorand], value: ChainProtocol.Algorand },
  { label: READABLE_PROTOCOL[ChainProtocol.Arbitrum], value: ChainProtocol.Arbitrum },
  { label: READABLE_PROTOCOL[ChainProtocol.Ethereum], value: ChainProtocol.Ethereum },
  { label: READABLE_PROTOCOL[ChainProtocol.Etherlink], value: ChainProtocol.Etherlink },
  { label: READABLE_PROTOCOL[ChainProtocol.Hedera], value: ChainProtocol.Hedera },
  { label: READABLE_PROTOCOL[ChainProtocol.Polygon], value: ChainProtocol.Polygon },
  { label: READABLE_PROTOCOL[ChainProtocol.Solana], value: ChainProtocol.Solana },
  { label: READABLE_PROTOCOL[ChainProtocol.XDC], value: ChainProtocol.XDC },
  { label: READABLE_PROTOCOL[ChainProtocol.XRP_Ledger], value: ChainProtocol.XRP_Ledger },
].filter((it) => !DISABLED_CHAINS.includes(it.value))

function AddAddressForm({ traderId, onSuccess }: AddAddressProps) {
  const { mutate } = useMutation(
    (data: AddTraderAddressOperationData) => createOperation(OperationType.AddTraderAddress, data, undefined, traderId),
    {
      onSuccess: () => {
        toast.success('Add address request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: AddressCreationFormData) => {
    const operationData = {
      addressName: data.name,
      address: data.address,
      protocol: data.protocol,
    }
    mutate(operationData)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<AddressCreationFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField name="name" control={control} label="New address name" />
      <Select name="protocol" control={control} label="Protocol" options={tokenProtocolOptions} />
      <TextField name="address" control={control} label="New address" />
      <ButtonGroup fullWidth>
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Add address
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default AddAddressForm
