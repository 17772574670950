import {
  ChainProtocol,
  GranteeType,
  OperationType,
  READABLE_KYC_STATUS,
  RevokeTraderKYCTokenFormData,
  TABLE_HEADER_NAMES,
  Token,
  TokenWhitelistTradersData,
  TraderAddress,
} from '@archax/shared-types'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import { GetTokenWhitelistParams, getTokenWhitelistTraders, getTokenWhitelistTradersCSVUrl } from '../../../api/tokens'
import Dialog from '../../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { AddButton } from '../../../components/ui/AddButton'
import { useGlobalContext } from '../../../context'
import { substringFilterParams } from '../../../util/common-grid-options'
import onApiError from '../../../util/on-api-error'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { hasUserRole } from '../../../util/user-roles'
import { GrantKYCTokenForm } from './GrantKYCTokenForm'
import { MintToTokenForm } from './MintToTokenForm'
import { SendNFTTokenForm } from './SendNFTTokenForm'
import { SendTokenForm } from './SendTokenForm'
import { WipeNFTTokenForm } from './WipeNFTTokenForm'
import { WipeTokenForm } from './WipeTokenForm'
interface TokenTradersWhitelistProps {
  token: Token
  isNft: boolean
  canMint: boolean
  canSend: boolean
}

interface RevokeKYCOperationData {
  formData: RevokeTraderKYCTokenFormData
  traderId: string
}

function TokenWhitelistTraders({ token, isNft, canMint, canSend }: TokenTradersWhitelistProps) {
  const { id, kycEnabled } = token
  const [showSendTokenDialog, setShowSendTokenDialog] = useState(false)
  const [showMintToTokenDialog, setShowMintToTokenDialog] = useState(false)
  const [showWipeTokenDialog, setShowWipeTokenDialog] = useState(false)
  const [showGrantKYCTokenDialog, setShowGrantKYCTokenDialog] = useState(false)
  const [showRevokeKYCTokenDialog, setShowRevokeKYCTokenDialog] = useState(false)
  const [showRemoveTraderAddressDialog, setShowRemoveTraderAddressDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TraderAddress | null>(null)
  const [selectedTrader, setSelectedTrader] = useState<TokenWhitelistTradersData | null>(null)
  const {
    state: { user },
  } = useGlobalContext()

  const { t } = useTranslation()

  const { mutate: revokeKYCMutation } = useMutation(
    (data: RevokeKYCOperationData) => createOperation(OperationType.RevokeKYC, data.formData, id, data.traderId),
    {
      onSuccess: () => {
        toast.success('Revoke KYC request sent for approval')
      },
      onError: onApiError,
    },
  )

  const { mutate: removeTraderAddressMutation } = useMutation(
    (data: RevokeKYCOperationData) =>
      createOperation(OperationType.RemoveTraderAddressFromWhiteList, data.formData, id, data.traderId),
    {
      onSuccess: () => {
        toast.success(t('holder.toast.removeAddressFromWhitelist'))
      },
      onError: onApiError,
    },
  )

  const handleRevokeKYC = async (row: TraderAddress) => {
    const formData = { traderAddressId: row.id }
    revokeKYCMutation({ formData, traderId: row.trader.id })
    setShowRevokeKYCTokenDialog(false)
  }

  const handleRemoveTraderAddress = async (row: TraderAddress) => {
    const formData = { traderAddressId: row.id }
    removeTraderAddressMutation({ formData, traderId: row.trader.id })
    setShowRemoveTraderAddressDialog(false)
  }

  const columnDefs: ColDef<TokenWhitelistTradersData>[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: TABLE_HEADER_NAMES.token_whitelist.is_whitelisted,
        minWidth: 130,
        valueGetter: (params: ValueGetterParams<TokenWhitelistTradersData>) => {
          return params.data!.isWhitelisted ? READABLE_KYC_STATUS.ENABLED : READABLE_KYC_STATUS.DISABLED
        },
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(READABLE_KYC_STATUS),
        },
      },
      {
        field: 'trader_name',
        headerName: t('headers.common.holderName'),
        flex: 1,
        minWidth: 160,
        valueGetter: (params: ValueGetterParams<TokenWhitelistTradersData>) => {
          return params.data!.traderAddress.trader.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'onChainData.balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 130,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<TokenWhitelistTradersData>) => {
          if (params.data!.onChainData.balance === undefined || params.data!.onChainData.balance === null) {
            return (
              <Tooltip title="Could not retrieve the balance">
                <Typography>
                  <WarningIcon />
                </Typography>
              </Tooltip>
            )
          }

          return tokenBalanceFormatter(params.data!.onChainData.balance, token.decimals)
        },
      },
      {
        field: 'address_name',
        headerName: TABLE_HEADER_NAMES.common.address_name,
        flex: 1,
        minWidth: 160,
        valueGetter: (params: ValueGetterParams<TokenWhitelistTradersData>) => {
          return params.data!.traderAddress.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_address',
        headerName: t('headers.common.holderAddress'),
        flex: 3,
        minWidth: 220,
        valueGetter: (params: ValueGetterParams<TokenWhitelistTradersData>) => {
          return params.data!.traderAddress.address
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
    ],
    [token.decimals],
  )

  const userRoleColumnDefs: ColDef<TokenWhitelistTradersData>[] = useMemo(
    () => [
      {
        field: 'actions',
        flex: 1,
        minWidth: 400,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<TokenWhitelistTradersData>) => {
          const isWhitelisted = params.data!.isWhitelisted
          const canWipe = BigInt(params.data!.onChainData.balance || 0) > BigInt(0) && !token.hasExternalOwner
          return (
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                {isWhitelisted && (
                  <>
                    <Box marginRight={1}>
                      <Button
                        data-testid="token-whitelist__trader__send-button"
                        disabled={!canSend || token.hasExternalOwner}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedRow(params.data!.traderAddress)
                          setShowSendTokenDialog(true)
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                    <Box marginRight={1}>
                      <Button
                        disabled={!canMint || token.hasExternalOwner}
                        data-testid="token-whitelist__trader__mint-button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedRow(params.data!.traderAddress)
                          setShowMintToTokenDialog(true)
                        }}
                      >
                        Mint
                      </Button>
                    </Box>
                  </>
                )}
                {
                  <Box marginRight={1}>
                    <Button
                      disabled={!canWipe}
                      data-testid="token-whitelist__trader__wipe-button"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedRow(params.data!.traderAddress)
                        setSelectedTrader(params.data!)
                        setShowWipeTokenDialog(true)
                      }}
                    >
                      Wipe
                    </Button>
                  </Box>
                }
                {kycEnabled && (
                  <Box marginRight={1}>
                    <Button
                      sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedRow(params.data!.traderAddress)
                        isWhitelisted ? setShowRevokeKYCTokenDialog(true) : setShowGrantKYCTokenDialog(true)
                      }}
                      disabled={token.hasExternalOwner}
                    >
                      {isWhitelisted ? 'Revoke KYC' : 'Grant KYC'}
                    </Button>
                  </Box>
                )}
                {!kycEnabled && (
                  <Box marginRight={1}>
                    <Button
                      sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedRow(params.data!.traderAddress)
                        setShowRemoveTraderAddressDialog(true)
                      }}
                      disabled={token.hasExternalOwner}
                    >
                      Remove
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          )
        },
      },
    ],
    [canMint, canSend, kycEnabled],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: hasUserRole(user) ? [...columnDefs, ...userRoleColumnDefs] : columnDefs,
    }),
    [columnDefs, userRoleColumnDefs, user],
  )

  return (
    <div data-testid="token-whitelist">
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          {t('holder_one')}
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {hasUserRole(user) && (
            <AddButton
              data-testid="token-whitelist__add-button"
              name="whitelist-addition"
              ariaLabel="Grant KYC"
              onClick={() => {
                setSelectedRow(null)
                setShowGrantKYCTokenDialog(true)
              }}
              disabled={token.hasExternalOwner}
            ></AddButton>
          )}
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getTokenWhitelistTraders({ ...(gridParams as GetTokenWhitelistParams), id })
          }}
          csvExportUrlGetter={(gridParams) => {
            return getTokenWhitelistTradersCSVUrl({
              ...(gridParams as GetTokenWhitelistParams),
              id,
            })
          }}
        />
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        title={`Grant KYC`}
        onClose={() => setShowGrantKYCTokenDialog(false)}
        open={showGrantKYCTokenDialog}
      >
        <GrantKYCTokenForm
          granteeType={GranteeType.Trader}
          granteeId={selectedRow?.trader.id}
          traderAddressId={selectedRow?.id}
          onSuccess={() => setShowGrantKYCTokenDialog(false)}
          token={token}
        />
      </Dialog>
      <Dialog
        title={`Send tokens to: ${selectedRow?.trader?.name}(${selectedRow?.name})`}
        onClose={() => setShowSendTokenDialog(false)}
        open={showSendTokenDialog}
      >
        {isNft && (
          <SendNFTTokenForm
            onSuccess={() => setShowSendTokenDialog(false)}
            tokenId={id}
            account={selectedRow!}
            token={token}
            assets={token.onChainData?.assets!}
          />
        )}
        {!isNft && (
          <SendTokenForm onSuccess={() => setShowSendTokenDialog(false)} token={token} account={selectedRow!} />
        )}
      </Dialog>
      <Dialog
        title={`${isNft ? 'Mint token to' : 'Mint tokens to'}: ${selectedRow?.name}(${selectedRow?.name})`}
        onClose={() => setShowMintToTokenDialog(false)}
        open={showMintToTokenDialog}
      >
        <MintToTokenForm
          onSuccess={() => setShowMintToTokenDialog(false)}
          token={token}
          account={selectedRow!}
          isNft={isNft}
        />
      </Dialog>
      <Dialog
        title={`Wipe tokens from: ${selectedRow?.trader?.name}(${selectedRow?.name})`}
        onClose={() => setShowWipeTokenDialog(false)}
        open={showWipeTokenDialog}
      >
        {isNft && (
          <WipeNFTTokenForm
            onSuccess={() => setShowWipeTokenDialog(false)}
            tokenId={id}
            account={selectedRow!}
            trader={selectedTrader!}
          />
        )}
        {!isNft && (
          <WipeTokenForm onSuccess={() => setShowWipeTokenDialog(false)} token={token} account={selectedRow!} />
        )}
      </Dialog>
      <Dialog
        title="Are you sure that you want to revoke account access to the token?"
        onConfirm={() => handleRevokeKYC(selectedRow!)}
        confirmLabel="Revoke KYC"
        onClose={() => setShowRevokeKYCTokenDialog(false)}
        open={showRevokeKYCTokenDialog}
        showCancel
      >
        <Typography variant="body2">You can grant the KYC back later.</Typography>
      </Dialog>
      <Dialog
        title={t('holder.dialog.removeAddressFromWhitelist')}
        onConfirm={() => handleRemoveTraderAddress(selectedRow!)}
        confirmLabel="Remove"
        onClose={() => setShowRemoveTraderAddressDialog(false)}
        open={showRemoveTraderAddressDialog}
        showCancel
      >
        <Typography variant="body2">You can add the address back later.</Typography>
      </Dialog>
    </div>
  )
}

export default TokenWhitelistTraders
